import { useEffect, type ReactElement, useState } from 'react';
import { type LogtoContext } from '@logto/next';
import { useSeoConfig } from '@uxu/design-system/hooks/useSeoConfig';
import { LayoutFull } from '@uxu/design-system/components/layout/layoutFull';
import { footerConfig, headerMenuConfig } from "../config";
import { SectionHello } from "../components";
import { authIsAuthenticatedGet } from '../utils/query/auth';
import { useAppSelector } from '@uxu/design-system/store';
import { useRouter } from 'next/router';
import { haveAccess } from '@uxu/utils/frontend/auth';

export default function Index(): ReactElement {
    const router = useRouter();
    const hasAccess = haveAccess();

    useEffect(() => {
        if (hasAccess.isAccess) {
            void router.push('/dashboard');
        }
    }, [hasAccess]);

    const seo = useSeoConfig({});
    return (
        <LayoutFull
            footer={footerConfig}
            headerMenu={headerMenuConfig}
            seo={seo}
        >
            <SectionHello />
        </LayoutFull>
    );
};
